import { SecuredLayout } from '../Layout/SecuredLayout';
import { Autocomplete, Box, IconButton, InputLabel, Paper, TextField, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { Line, LineStop, OutOfScheduleTravelDate, UpdateLine } from '../../@types/line';
import SaveIcon from '@mui/icons-material/Save';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import hu from 'date-fns/locale/hu';
import StopForm from './StopForm';
import { useAuth, useProvideSnackBar } from '../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import PlaceIcon from '@mui/icons-material/Place';
import DayCheckboxes, { dayLabels } from '../Common/DayCheckboxes';
import { useAllCRMStops } from '../../hooks/useAllCRMStops';
import ClearIcon from '@mui/icons-material/Clear';
import { ArrowBackIosNew } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyConfirmModal from './CopyConfirmModal';
import ConfirmModal from './ConfirmModal';
import cloneDeep from 'lodash/cloneDeep';
import { formatDateToYyyyMMdd } from '../../utils/date-format-handler';
import { isValid } from 'date-fns';
import OutOfScheduleDatesManager from './OutOfScheduleDatesManager';

const RouteForm = () => {
    const navigator = useNavigate();
    const { user } = useAuth();
    const { id } = useParams();
    const allCRMStops = useAllCRMStops();

    const { showSuccess, showError, showResponseError } = useProvideSnackBar();
    const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

    const [trailID, setTrailID] = useState<string>('');
    const [validityFrom, setValidityFrom] = useState<Date>(new Date());
    const [validityTo, setValidityTo] = useState<Date | null>(null);
    const [version, setVersion] = useState<number>(1);
    const [lineNumber, setLineNumber] = useState<string>('');
    const [existingLineNumbers, setExistingLineNumbers] = useState<string[]>([]);
    const [OutOfScheduleTravelDates, setOutOfScheduleTravelDates] = useState<OutOfScheduleTravelDate[]>([]);

    const [stops, setStops] = useState<LineStop[]>([
        { ID: crypto.randomUUID(), Location: '', DepartureTime: '', GpsCoordinate: '', Comment: '', Route: '', Order: 0 },
        { ID: crypto.randomUUID(), Location: '', DepartureTime: '', GpsCoordinate: '', Comment: '', Route: '', Order: 0 },
    ]);
    const [selectedTravelDays, setSelectedTravelDays] = useState<string[]>([]);
    const [lineID, setLineID] = useState<number | undefined>(undefined);
    const [originalState, setOriginalState] = useState<Line>({
        ID: 0,
        TrailID: '',
        LineNumber: null,
        Forda: null,
        Validity: {
            From: cloneDeep(validityFrom),
            To: null,
        },
        Transport: [],
        OutOfScheduleTravelDates: [],
        LineStops: cloneDeep(stops),
        Version: 1,
    });

    const handleDeleteStop = (id: string) => {
        const newStops = stops.filter(stop => stop.ID !== id);
        setStops(newStops);
    };

    useEffect(() => {
        if (id === 'create' || id === undefined) return;
        try {
            const idNumber = parseInt(id);
            setLineID(idNumber);
        } catch (e) {
            console.error('Failed to parse lineId');
            return;
        }
    }, []);

    useEffect(() => {
        if (lineID === undefined) return;
        fetchLine();
    }, [lineID]);

    const fetchLine = async () => {
        try {
            const response = await fetch(`/api/v1/line/${lineID}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });

            if (response.ok) {
                const existingLine: Line = await response.json();
                setOriginalState(existingLine);
                setTrailID(existingLine.TrailID);
                if (existingLine.LineNumber) {
                    setLineNumber(existingLine.LineNumber.toString());
                }
                setStops(existingLine.LineStops);
                if (existingLine.Validity.From !== null) {
                    setValidityFrom(new Date(existingLine.Validity.From || ''));
                }
                if (existingLine.Validity.To !== null && isValid(new Date(existingLine.Validity.To))) {
                    setValidityTo(new Date(existingLine.Validity.To));
                }
                setOutOfScheduleTravelDates(existingLine.OutOfScheduleTravelDates.map(date => ({ Date: new Date(date.Date), Operates: date.Operates })));
                setVersion(existingLine.Version);
                setSelectedTravelDays(dayLabels.filter(day => existingLine.Transport.some((transport: string) => transport === day)));
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            navigator('/404', { replace: true });
        }
    };

    const newStopAllowed = (): boolean => {
        return stops.every(s => !!s.DepartureTime && !!s.GpsCoordinate && !!s.Location);
    };

    const handleAddStopButton = () => {
        if (!newStopAllowed()) {
            showError('Nem adható hozzá új megálló, amíg az előző megálló adatai nincsenek kitöltve');
            return;
        }

        setStops(prev => [
            ...prev.slice(0, -1),
            {
                ID: crypto.randomUUID(),
                Location: '',
                DepartureTime: '',
                GpsCoordinate: '',
                Comment: '',
                Route: '',
                Order: 0,
            },
            stops[stops.length - 1],
        ]);
    };

    const allStopsHaveCoordinates = (lineStops: LineStop[]): boolean => lineStops.every(stop => stop.GpsCoordinate);

    const handleUpdateStops = async (oldValue: LineStop, newValue: LineStop, updateRoute: boolean) => {
            const updatedStops = [...stops];
            const index = updatedStops.findIndex(item => item.ID === oldValue.ID);
            updatedStops[index] = newValue;

            setStops(updatedStops);

            if (updateRoute && lineID) {
                await handleSave(false, updatedStops);
                fetchLine();
            }
    }

    const handleSave = async (navigate: boolean, lineStops: LineStop[]) => {
        const errors = validateForm(lineStops);
        if (errors.length) {
            errors.forEach(error => showError(error));
            return;
        }
        let data: UpdateLine = {
            TrailID: trailID,
            LineNumber: lineNumber.trim() === '' ? '' : lineNumber,
            Validity: {
                From: formatDateToYyyyMMdd(validityFrom),
                To: validityTo && isValid(new Date(validityTo)) ? formatDateToYyyyMMdd(validityTo) : '',
            },
            Transport: selectedTravelDays,
            OutOfScheduleTravelDates: OutOfScheduleTravelDates.map(date => ({ Date: formatDateToYyyyMMdd(date.Date), Operates: date.Operates })),
            LineStops: lineStops,
            Version: version,
        };
        try {
            const url = '/api/v1/line' + (lineID ? '/' + lineID : '');
            const response = await fetch(url, {
                method: lineID ? 'PUT' : 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                showSuccess('Sikeres ' + (lineID ? 'frissítés' : 'mentés'));
                if (navigate) { navigator(`/`) }
                return;
            }
            if (response.status === 404) {
                showError('A járat nem található');
                return;
            }

            if (response.status === 422) {
                const errData = await response.json();
                    showError(errData.error);
                return;
            }
            showResponseError(response);
        } catch (error: any) {
            showError('Hiba történt az járat ' + (lineID ? 'frissítés' : 'mentés') + ' közben');
        }
    };

    const fetchLineCopy = async () => {
        try {
            const response = await fetch(`/api/v1/line/${lineID}/copy`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                const newLineID = data.ID;
                setLineID(newLineID);
                navigator(`/${newLineID}`);
                showSuccess('Sikeres másolás');
            } else {
                showResponseError(response);
            }
        } catch (error: any) {
            showError(`Hiba történt a járat másolása közben`);
        }
        setCopyModalOpen(false);
    };

    const fetchUniqLineNumbers = async () => {
        try {
            const response = await fetch('api/v1/line_number', {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.accessToken}`,
                },
            });
            if (response.ok) {
                const lineNumbers = await response.json();
                setExistingLineNumbers(lineNumbers);
            } else {
                throw new Error('Failed to fetch names');
            }
        } catch (error: any) {
            showError(`Hiba történt: ${error.message}`);
        }
    };

    useEffect(() => {
        fetchUniqLineNumbers();
    }, []);

    const validateForm = (updatedStops: LineStop[]) => {
        const errors = [];

        if (!allStopsHaveCoordinates(updatedStops)) {
            errors.push('Nem minden megállónál van megadva GPS koordináta');
        }

        if (!trailID) {
            errors.push('Hiányzó nyomvonal azonosító');
        }

        if (!selectedTravelDays.length) {
            errors.push('Hiányzó járat napok');
        }

        if (stops.some(s => !s.DepartureTime)) {
            errors.push('Nem minden megállónál van megadva indulási idő');
        }

        return errors;
    };

    const hasChanges = () => {
        const data = {
            TrailID: trailID,
            LineNumber: lineNumber,
            Validity: {
                From: validityFrom,
                To: validityTo,
            },
            Transport: selectedTravelDays.sort(),
            OutOfScheduleTravelDates: OutOfScheduleTravelDates,
            LineStops: stops,
        };
        return (
            JSON.stringify({
                TrailID: originalState?.TrailID,
                LineNumber: originalState.LineNumber ? originalState?.LineNumber.toString() : '',
                Validity: {
                    From: new Date(originalState?.Validity.From || ''),
                    To: new Date(originalState?.Validity.To || ''),
                },
                Transport: originalState?.Transport.sort(),
                OutOfScheduleTravelDates: originalState?.OutOfScheduleTravelDates.map(date => ({ Date: new Date(date.Date), Operates: date.Operates })),
                LineStops: originalState?.LineStops,
            }) !== JSON.stringify(data)
        );
    };

    useEffect(() => {
        const onUnload = (e: BeforeUnloadEvent) => {
            if (hasChanges()) {
                e.preventDefault();
                e.returnValue = 'Az oldalon nem mentett változások vannak. Biztosan elnavigál az oldalról?';
            }
        };

        window.addEventListener('beforeunload', onUnload);

        return () => window.removeEventListener('beforeunload', onUnload);
    }, [trailID, validityFrom, validityTo, selectedTravelDays, stops]);

    const handleSaveAndNavigate = async () => {
        const errors = validateForm(stops);

        if (errors.length) {
            errors.forEach(error => showError(error));
            setConfirmModalOpen(false);
            return;
        }
        await handleSave(true, stops);
    };

    const getDayAbbreviation = (date: Date) => {
        switch (date.getDay()) {
            case 0:
                return 'V';
            case 1:
                return 'H';
            case 2:
                return 'K';
            case 3:
                return 'Sze';
            case 4:
                return 'Cs';
            case 5:
                return 'P';
            case 6:
                return 'Szo';
            default:
                return '';
        }
    };

    const handleAddOutOfScheduleTravelDates = (date: Date, travel: boolean) => {
        if (OutOfScheduleTravelDates.some(d => d.Date.getTime() === date.getTime())) {
            showError('Ez a dátum már hozzá lett adva');
            return;
        }
        setOutOfScheduleTravelDates(prevDates => [...prevDates, { Date: date, Operates: travel }]);
    };

    const handleDeleteOutOfScheduleTravelDates = (date: Date) => {
        setOutOfScheduleTravelDates(prevDates => prevDates.filter(d => d.Date !== date));
    };

    return (
        <SecuredLayout>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                <Grid container p={3} justifyContent={'center'}>
                    <Paper
                        sx={{
                            p: 2,
                            height: 'fit-content',
                            width: '60rem',
                            flexDirection: 'column',
                            display: 'flex',
                            padding: 1,
                            marginTop: 1,
                        }}>
                        <Typography variant={'h4'} p={2}>
                            Járat részletei
                        </Typography>
                        <Grid container p={1} py={3} spacing={2} justifyContent={'center'} alignItems={'flex-end'}>
                            <Grid xs={12} sm={6} md={3}>
                                <TextField
                                    fullWidth
                                    focused
                                    variant={'standard'}
                                    label='Nyomvonal azonosító'
                                    value={trailID}
                                    onChange={e => setTrailID(e.target.value)}
                                />
                            </Grid>
                            <Grid xs={12} sm={6} md={3}>
                                <Autocomplete
                                    defaultValue={lineNumber}
                                    value={lineNumber}
                                    onKeyUp={event => {
                                        const newValue = (event.target as HTMLInputElement).value;
                                        if (!newValue) return;
                                        setLineNumber(newValue);
                                    }}
                                    onChange={(_, newValue) => {
                                        if (!newValue) return;
                                        if (!existingLineNumbers.some(option => option.toString() === newValue.toString())) {
                                            setExistingLineNumbers([...existingLineNumbers, newValue]);
                                        }
                                        setLineNumber(newValue);
                                    }}
                                    filterOptions={(options, state) => {
                                        const displayOptions = options.filter(option => option.toString().includes(state.inputValue.toString()));
                                        if (!displayOptions.some(option => option.toString() === state.inputValue.toString())) {
                                            displayOptions.push(state.inputValue);
                                        }
                                        return displayOptions;
                                    }}
                                    selectOnFocus
                                    disableClearable
                                    handleHomeEndKeys
                                    options={existingLineNumbers}
                                    getOptionLabel={option => {
                                        return option.toString();
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option}>
                                            {option}
                                        </li>
                                    )}
                                    renderInput={params => <TextField {...params} label='Járatszám' variant='standard' />}
                                    freeSolo
                                />
                            </Grid>

                            <Grid xs={12} sm={4} md={3} justifyContent={'center'} alignItems={'center'}>
                                <DateRangePicker
                                    label='Érvényesség'
                                    value={[validityFrom, validityTo]}
                                    onChange={newValue => {
                                        if (newValue[0] !== null) {
                                            setValidityFrom(newValue[0]);
                                        }
                                        if (newValue[1] !== null) {
                                            setValidityTo(newValue[1]);
                                        }
                                    }}
                                    slots={{ field: SingleInputDateRangeField }}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            sx: {
                                                width: '200px',
                                            },
                                            error: false,
                                        },
                                    }}
                                    calendars={2}
                                />
                            </Grid>

                            <Grid xs={12} sm={4} md={3}>
                                <InputLabel id='days-label' sx={{ transform: 'translate(0, -1.5px) scale(0.75)' }}>
                                    Közlekedik
                                </InputLabel>
                                <Box sx={{ marginTop: 0.125 }}>
                                    <DayCheckboxes
                                        collection={selectedTravelDays}
                                        handleOnChange={(val: boolean, day: string) => {
                                            if (val) {
                                                setSelectedTravelDays(prev => [...prev, day]);
                                                setOutOfScheduleTravelDates(prevDates => prevDates.filter(date => getDayAbbreviation(date.Date) !== day));
                                                return;
                                            }
                                            setSelectedTravelDays(prev => prev.filter(d => d !== day));
                                            setOutOfScheduleTravelDates(prevDates => prevDates.filter(date => getDayAbbreviation(date.Date) !== day));
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} p={1} justifyContent={'center'}>
                            <Grid xs={12} sm={6} sx={{ textAlign: 'left' }}>
                                <Typography mb={1}>
                                    <strong>Menetrendtől eltérően közlekedik</strong>
                                </Typography>
                                <OutOfScheduleDatesManager
                                    travel={true}
                                    dates={OutOfScheduleTravelDates.filter(date => date.Operates).map(date => date.Date)}
                                    onAddDate={handleAddOutOfScheduleTravelDates}
                                    onDeleteDate={handleDeleteOutOfScheduleTravelDates}
                                />
                            </Grid>

                            <Grid xs={12} sm={6} sx={{ textAlign: 'left' }}>
                                <Typography mb={1}>
                                    <strong>Menetrendtől eltérően NEM közlekedik</strong>
                                </Typography>
                                <OutOfScheduleDatesManager
                                    travel={false}
                                    dates={OutOfScheduleTravelDates.filter(date => !date.Operates).map(date => date.Date)}
                                    onAddDate={handleAddOutOfScheduleTravelDates}
                                    onDeleteDate={handleDeleteOutOfScheduleTravelDates}
                                />
                            </Grid>
                        </Grid>

                        {stops.map((stop, idx) => (
                            <StopForm
                                icon={
                                    idx === 0 ? (
                                        <PlaceIcon color={'info'} />
                                    ) : idx === stops.length - 1 ? (
                                        <PlaceIcon color={'primary'} />
                                    ) : (
                                        <ClearIcon color={'error'} />
                                    )
                                }
                                key={stop.ID}
                                stop={stop}
                                deletable={idx > 0 && idx < stops.length - 1}
                                onDelete={handleDeleteStop}
                                editableStops={stops}
                                allCrmStops={allCRMStops}
                                onChange={(newValue) => {
                                    handleUpdateStops(stop, newValue, (stop.GpsCoordinate !== newValue.GpsCoordinate && stop.Location !== "" && stop.DepartureTime !== ""))
                                }}
                                handleOnBlur={(newValue) => {
                                    handleUpdateStops(stop, newValue, (stop.Location !== "" && stop.DepartureTime !== "" && stop.GpsCoordinate !== ""))
                                }}
                            />
                        ))}
                        <Grid container justifyContent={'end'} pt={5} pr={2} spacing={1}>
                            <Grid>
                                <Tooltip title='Megálló hozzáadása' arrow>
                                    <IconButton size={'large'} color={'warning'} onClick={handleAddStopButton}>
                                        <AddLocationAltIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid>
                                <Tooltip title='Mentés' arrow>
                                    <IconButton
                                        size={'large'}
                                        color={'primary'}
                                        sx={{
                                            opacity: allStopsHaveCoordinates(stops) ? 1 : 0.5,
                                        }}
                                        onClick={() => handleSave(true, stops)}>
                                        <SaveIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            {lineID && (
                                <Grid>
                                    <Tooltip title='Menetrend másolás' arrow>
                                        <IconButton
                                            size={'large'}
                                            color={'primary'}
                                            onClick={() => {
                                                setCopyModalOpen(true);
                                            }}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            )}
                            <Grid>
                                <Tooltip title='Vissza' arrow>
                                    <IconButton
                                        size={'large'}
                                        color='info'
                                        sx={{ borderRadius: 50 }}
                                        onClick={() => {
                                            if (hasChanges()) {
                                                setConfirmModalOpen(true);
                                                return;
                                            }
                                            navigator('/');
                                        }}>
                                        <ArrowBackIosNew />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Paper>
                    <CopyConfirmModal
                        open={copyModalOpen}
                        onClose={() => {
                            setCopyModalOpen(false);
                        }}
                        onCopy={() => fetchLineCopy()}
                    />
                    <ConfirmModal
                        open={confirmModalOpen}
                        onClose={() => {
                            setConfirmModalOpen(false);
                        }}
                        onSave={handleSaveAndNavigate}
                    />
                </Grid>
            </LocalizationProvider>
        </SecuredLayout>
    );
};
export { RouteForm };
